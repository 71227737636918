/* eslint-disable */
const routes = require('next-routes')

module.exports = routes()
  .add('index')
  .add('section', '/section/:sectionName/:foundRow?/:regionCode?')
  .add('search', '/search/:text?')
  .add('reports')
  .add('topics')
/*eslint-enable */
